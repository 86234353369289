import type { FC } from 'react'
import type { Locale } from '@model/locales'
import { KK_LANG_IDS } from '@model/locales'
import { makeConfig } from '@components/category/HomePageCollection/config'
import type { CollectionType } from '@api/categories'
import { HomePageCollection } from '@components/category/HomePageCollection'
import { useRouter } from 'next/router'
import type { Catalog } from '@model/pricelist/Catalog'
import { CwsSection } from '@components/cws'

export interface ProductsCollectionProps {
  collection: string
  collections: string[]
}

export const ProductsCollection: FC<Partial<ProductsCollectionProps>> = ({ collections, collection }) => {
  const {
    locale,
    query: { catalogId = 'shop' },
  } = useRouter()
  const languageId = KK_LANG_IDS[locale as Locale]
  const collectionsMap = makeConfig(languageId, catalogId as Catalog)

  let collectionList: string[] = []
  let hideTitle = false
  if (!collections && !collection) {
    return null
  }

  if (collections) collectionList = collections
  else if (collection) {
    collectionList = [collection]
    hideTitle = true
  }

  const listNames: { [key: string]: string } = {
    'new-c': 'new_products',
    'recommendations-c': 'recommended_products',
    'monthly-offer-c': 'monthly_offer_products',
  }
  return (
    <CwsSection className="cws-pt-l">
      {collectionList.map((key,index,array) => {
        const cMapKey = key as CollectionType
        const collection = collectionsMap[cMapKey]
        if (collection) {
          return (
            <HomePageCollection
              key={cMapKey}
              name={cMapKey}
              isLast={collection.isLast}
              fetcher={collection.fetcher}
              title={collection.title}
              description={collection.description}
              hideArrow={cMapKey === 'recommendations-c'}
              hideTitle={hideTitle}
              listName={listNames[cMapKey] ?? cMapKey}
              lastSection={index+1 === array.length}
            />
          )
        } else {
          return null
        }
      })}
    </CwsSection>
  )
}
