import type { CollectionType } from '@api/categories'
import { collectionsData } from '@api/categories'
import type { LanguageId } from '@model/locales'
import axios from 'axios'
import type { Catalog } from '@model/pricelist/Catalog'

export interface HomePageCollectionConfigItem {
  isLast?: boolean
  fetcher(): Promise<any>
  title: string
  description: string
  hideArrow?: boolean
  hideTitle?: boolean
}

export type HomePageCollectionConfig = {
  [key in CollectionType]: HomePageCollectionConfigItem
}

const dummyCollectionDescription = ''
// 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut at ornare quam. Fusce vitae lorem vitae neque imperdiet laoreet nec id ipsum.'

const makeFetcherForCollection = (name: CollectionType, languageId: LanguageId, catalogId: Catalog) => {
  return async () => {
    const { data } = await axios.post(`/api/collection/${name}`, {
      languageId,
      catalogId,
      limit: 16,
      custom: collectionsData[name].custom,
    })
    return data
  }
}

export const makeConfig = (languageId: LanguageId, catalogId: Catalog) => {
  const collectionsConfig: HomePageCollectionConfig = {
    'recommendations-c': {
      isLast: false,
      title: 'collection.name.s2.recommendations',
      fetcher: async () => {
        const { data } = await axios.post('/api/customer-recommendations', {
          languageId,
          catalogId,
        })
        return data
      },
      description: dummyCollectionDescription,
    },
    'monthly-offer-c': {
      isLast: false,
      title: 'collection.name.monthly-offer',
      fetcher: makeFetcherForCollection('monthly-offer-c', languageId, catalogId),
      description: dummyCollectionDescription,
    },
    'new-c': {
      isLast: false,
      title: 'collection.name.new',
      fetcher: makeFetcherForCollection('new-c', languageId, catalogId),
      description: dummyCollectionDescription,
    },
    'best-sellers-c': {
      isLast: false,
      title: 'collection.name.s2.best-sellers',
      fetcher: makeFetcherForCollection('best-sellers-c', languageId, catalogId),
      description: dummyCollectionDescription,
    },
    'beverages-c': {
      isLast: true,
      title: 'collection.name.s2.beverages',
      fetcher: makeFetcherForCollection('beverages-c', languageId, catalogId),
      description: dummyCollectionDescription,
    },
    'club-one-c': {
      isLast: true,
      title: 'collection.name.club-one',
      fetcher: makeFetcherForCollection('club-one-c', languageId, catalogId),
      description: dummyCollectionDescription,
    },
    'on-sale-c': {
      isLast: true,
      title: 'collection.name.on-sale',
      fetcher: makeFetcherForCollection('on-sale-c', languageId, catalogId),
      description: dummyCollectionDescription,
    },
    'travel-retail-exclusive-c': {
      isLast: false,
      title: 'collection.name.travel-retail-exclusive',
      fetcher: makeFetcherForCollection('travel-retail-exclusive-c', languageId, catalogId),
      description: dummyCollectionDescription,
    },
    'campaign-products-c': {
      isLast: true,
      title: 'collection.name.s2.campaign-products',
      fetcher: makeFetcherForCollection('campaign-products-c', languageId, catalogId),
      description: dummyCollectionDescription,
    },
  }
  return collectionsConfig
}
