import type { FC } from 'react'
import { Collection } from '@components/category/Collection'
import useSWRImmutable from 'swr/immutable'
import type { CollectionType } from '@api/categories'
import type { HomePageCollectionConfigItem } from '@components/category/HomePageCollection/config'
import { useTranslate } from '@hooks/useTranslation'
import type { CategoryPagePayload } from '@components/category/CategoryView/CategoryView'
import { useAppState } from '@context/state'
import { CwsDivider } from '@components/cws'
import clsx from 'clsx'
import { useRouter } from 'next/router'

export interface HomePageCollectionProps extends HomePageCollectionConfigItem {
  name: CollectionType
  listName: string
  lastSection?: boolean
}

const HomePageCollection: FC<HomePageCollectionProps> = ({
  name,
  fetcher,
  title,
  description,
  hideArrow,
  hideTitle = false,
  listName,
  lastSection,
}) => {
  const {
    state: { customer },
  } = useAppState()
  const { locale } = useRouter()
  const { id: customerId = '' } = customer || {}

  // let's check if the collection is recommendations
  const key = `HomePage-${name}-${customerId}-${locale}`
  const { data, isValidating, error } = useSWRImmutable<CategoryPagePayload>(key, fetcher)
  const { translate } = useTranslate()

  if (!Boolean(data) || isValidating || error) return null
  if (!Boolean(data?.category?.productArray?.length)) return null
  const collectionTranslation = translate(title) as string

  return (
    <>
      <Collection
        name={name}
        products={data?.category?.productArray || []}
        title={collectionTranslation}
        text={description}
        hideArrow={hideArrow}
        hideTitle={hideTitle}
        listName={listName}
      />
      <hr className={clsx('text-medium-grey cws-mt-xl ', !lastSection && 'cws-mb-xl')} />
    </>
  )
}

export default HomePageCollection
